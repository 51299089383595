import { defineStore } from 'pinia'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useModalStore = defineStore('modal', {
  state: () => ({
    addProductModalShown: false,
    noStockModalShown: false,
    inspireModalShown: false,
    b2bModalShown: false,
    image3dModalShown: false,
    noStockProduct: null,
    snackbar: {
      shown: false,
      type: 'success',
      message: '',
    },
    image: null,
    productAdded: null,
  }),
  getters: {
    getProductAdded() {
      return this.productAdded
    },
    getAddProductModalShown() {
      return this.addProductModalShown
    },
  },
  actions: {
    showProductModal() {
      this.addProductModalShown = true
    },
    closeProductModal() {
      this.addProductModalShown = false
    },
    setProductAdded(product) {
      this.productAdded = product
    },
    showErrorSnackbar(message) {
      this.snackbar.type = 'error'
      this.snackbar.message = message
      this.snackbar.shown = true
    },
    showSuccessSnackbar(message) {
      this.snackbar.type = 'success'
      this.snackbar.message = message
      this.snackbar.shown = true
    },
  },
})
